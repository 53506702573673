<template>
  <v-data-table
    :headers="tableHeaders"
    :items="workingList"
    :items-per-page="10"
    class="elevation-1"
    item-key="ipnd_id"
  >
    <template v-slot:[`item.label`]="{ item }">
<!--      Note. That multiple vendors can have IPNDs with the same values.-->
<!--      However we just take the first vendor details for the link.-->
<!--      We can match other IPND from other vendors from the IPND generic details.-->
<!--      Note this process select generic values being added the list. EG with no ids. -->
      <router-link v-if="portaAccountData != null && item.ids != null" :to="{name: 'TrunkIPNDDetails', params: {customerId:portaAccountData.i_customer, trunkId:portaAccountData.i_account,vendorNumber:Object.keys(item.ids)[0], ipndId: item.ids[Object.keys(item.ids)[0]][0]}}">
        {{item.label}}
      </router-link>
      <span v-else>
        {{item.label}}
      </span>

    </template>
    <template v-slot:[`item.customer_name_1`]="{ item }">
      <span>{{item.customer_name_1}} {{item.customer_name_2}}</span>
    </template>

    <template v-slot:[`item.ids`]="{ item }">
      {{getIdString(item)}}
    </template>

    <template v-slot:[`item.confirmed`]="{ item }">
      <v-chip :small="true" :color="'success'" v-if="item.confirmed === true || item.confirmed === '1' || item.confirmed === 1 || item.confirmed === 'true'">
        Confirmed
      </v-chip>
      <v-chip :small="true" :color="'error'" v-else-if="item.confirmed === false || item.confirmed === '0' || item.confirmed === 0 || item.confirmed === 'false'">
        Unconfirmed
      </v-chip>
      <v-chip :small="true" v-else>
        N/A
      </v-chip>
    </template>

    <template v-slot:[`item.selectButton`]="{ item }">
      <v-btn
        outlined
        block
        height="40"
        :loading="isBusy"
        @click="singleSelected(item)"
        class="primary"
        :dark="!$vuetify.theme.dark"
      >Select </v-btn>
    </template>

    <template v-slot:[`item.deleteButton`]="{ item }">
      <v-icon class="mr-2" v-if="deleteToggles[item.delete_string]" color="red">mdi mdi-loading mdi-spin</v-icon>
      <ButtonWithTooltip
        v-else
        icon="mdi-delete"
        :color="item.check_delete_error == null?'red':null"
        :disabled="item.check_delete_error != null"
        :tooltip="item.check_delete_error"
        @click="deleteIpnd(item)"
      />
    </template>
  </v-data-table>
</template>
<script>

import ButtonWithTooltip from "../ButtonWithTooltip.vue";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";

export default {
    name: 'IPNDDataTableListComponent',
    components: { ButtonWithTooltip },
    mixins: [GlobalHelperMixin],
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      ipndList:{
        type: Array,
        default: null
      },
      portaAccountData:{
        type: Object,
        default: null
      },
      /*
      single: select a single ipnd
       */
      selectType:{
        type: String,
        default: null
      }

  },
    data: () => ({
      busy:false,
      deleteToggles: { },
      workingList:[],
    }),
    methods: {
      singleSelected: function(ipndData){
        this.$emit('singleSelected', ipndData);
      },
      getIdString:function(item){
        let processed = [];

        if(item.ids_by_tag == null){
          return 'N/A';
        }

        for (const [key, value] of Object.entries(item.ids_by_tag)) {
          if(key == 'net-sip'){
            processed.push('NetSip:'+value);
          }else{
            if (process.env.NODE_ENV != 'production') {
              console.error('Unrecognised tag ('+key+').');
            }
          }
        }
        return processed.join('|');
      },
      async deleteIpnd(genericIpndValues) {
        this.deleteToggles[genericIpndValues.delete_string] = true;
        let label = genericIpndValues.label;
        const response = await this.Api.send('delete','trunks/'+this.portaAccountData.i_account+'/emergency-services-addresses/'+ Object.keys(genericIpndValues.ids)[0] + '/' + genericIpndValues.ids[Object.keys(genericIpndValues.ids)[0]][0]);
        this.deleteToggles[genericIpndValues.delete_string] = false;
        if (response.success) {
          //send a request up.
          this.$emit('updateIpndList');
        }else{
          this.showGlobalErrorMessage('Failed to delete Address ('+label+').');
        }
      },
      processIpndList( ){
        let deleteToggleList = {};
        let workingList = [];
        this.ipndList.forEach(function(ipnd) {
          let id = Object.keys(ipnd.ids)[0]+'-'+ipnd.ids[Object.keys(ipnd.ids)[0]][0];
          deleteToggleList[id] = false;
          ipnd.delete_string = id;
          workingList.push(ipnd);
        });
        this.deleteToggles = deleteToggleList;
        this.workingList = workingList;
      }
    },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
    tableHeaders: function( ){
      var header = [];

      if(this.$store.state.user.isAdmin){
        header.push({
          text: 'Vendor Details',
          align: 'start',
          sortable: true,
          value: 'ids',
        });
        header.push({
          text: 'Name',
          sortable: true,
          value: 'label',
        });
      }else{
        header.push({
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'label',
        });
      }

      header.push({
        text: 'Address',
        sortable: true,
        value: 'address_string',
      });

      header.push({
        text: 'Contact Name',
        sortable: true,
        value: 'customer_name_1',
      });

      header.push({
        text: 'Confirm',
        sortable: true,
        value: 'confirmed',
      });

      if(this.selectType == 'single'){
        header.push({
          text: '',
          sortable: false,
          value: 'selectButton',
        });
      }else{
        header.push({
          text: '',
          sortable: false,
          value: 'deleteButton',
        });
      }

      return header;
    }
  },
  watch: {
    ipndList: function(value){
      this.processIpndList( );
    }
  },
  created: function( ) {
    this.processIpndList( );
  },
};
</script>
