<template>
  <ComplexDialog
    title="Select Emergency Service Address"
    :loading="isBusy"
    v-model="visible"
    styledHeader
    :maxWidth="1000"
    >
      <IPNDDataTableListComponent :ipnd-list="ipndList" :select-type="'single'" :loading="isBusy" @singleSelected="singleSelected"/>
  </ComplexDialog>
</template>

<script>
import SimpleDialog from "../templates/SimpleDialog.vue";
import IPNDDataTableListComponent from "../../pieces/IPND/IPNDDataTableListComponent.vue";
import DialogMixin from "../../../mixins/dialogMixin";
import ComplexDialog from "../templates/ComplexDialog.vue";
/**
 * Note this is used to update the NetSip IPND.
 * It would need updating to cater for other vendors and there method.
 * ATM this is just NetSip.
 */
export default {
  name: 'IPNDListSelectDialog',
  mixins: [DialogMixin],
  components: { ComplexDialog, IPNDDataTableListComponent},
  data: () => ({

  }),
  computed: {
    isBusy: function () {
      return this.busy || this.loading;
    },
  },
  methods: {
    // reset() {
    // },
    singleSelected(ipndData){
      this.$emit('singleSelected', ipndData);
      this.visible = false;
    }
  },
  props: {
    ipndList: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },

  },

};
</script>