var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.workingList,"items-per-page":10,"item-key":"ipnd_id"},scopedSlots:_vm._u([{key:"item.label",fn:function(ref){
var item = ref.item;
return [(_vm.portaAccountData != null && item.ids != null)?_c('router-link',{attrs:{"to":{name: 'TrunkIPNDDetails', params: {customerId:_vm.portaAccountData.i_customer, trunkId:_vm.portaAccountData.i_account,vendorNumber:Object.keys(item.ids)[0], ipndId: item.ids[Object.keys(item.ids)[0]][0]}}}},[_vm._v(" "+_vm._s(item.label)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])]}},{key:"item.customer_name_1",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.customer_name_1)+" "+_vm._s(item.customer_name_2))])]}},{key:"item.ids",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getIdString(item))+" ")]}},{key:"item.confirmed",fn:function(ref){
var item = ref.item;
return [(item.confirmed === true || item.confirmed === '1' || item.confirmed === 1 || item.confirmed === 'true')?_c('v-chip',{attrs:{"small":true,"color":'success'}},[_vm._v(" Confirmed ")]):(item.confirmed === false || item.confirmed === '0' || item.confirmed === 0 || item.confirmed === 'false')?_c('v-chip',{attrs:{"small":true,"color":'error'}},[_vm._v(" Unconfirmed ")]):_c('v-chip',{attrs:{"small":true}},[_vm._v(" N/A ")])]}},{key:"item.selectButton",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary",attrs:{"outlined":"","block":"","height":"40","loading":_vm.isBusy,"dark":!_vm.$vuetify.theme.dark},on:{"click":function($event){return _vm.singleSelected(item)}}},[_vm._v("Select ")])]}},{key:"item.deleteButton",fn:function(ref){
var item = ref.item;
return [(_vm.deleteToggles[item.delete_string])?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v("mdi mdi-loading mdi-spin")]):_c('ButtonWithTooltip',{attrs:{"icon":"mdi-delete","color":item.check_delete_error == null?'red':null,"disabled":item.check_delete_error != null,"tooltip":item.check_delete_error},on:{"click":function($event){return _vm.deleteIpnd(item)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }