<template>
  <ComplexDialog
    :title="genericIpndData == null ? 'Add Emergency Service Address' : 'Edit Emergency Service Address'"
    v-model="visible"
    :loading="isBusy"
    :step="step"
    :step-end="4"
    :step-next-disabled="nextStepDisabled"
    :step-back-disabled="backStepDisabled"
    @step-next="next"
    @step-back="back"
    @step-end="finish"
    :step-end-text="'Close'"
    :step-next-text="nextLabel"
    :max-width="1000"
    :hideStepButtons="step == 1"
  >
    <v-window v-model="step">
      <v-window-item :value="1">
        <p><span v-if="genericIpndData == null">Add new</span> <span v-if="genericIpndData != null">Update the</span>  Emergency Services Address (IPND) address. Note this must be a valid business or government address registered by the Geoscape Geocoded National Address File (G-NAF).</p>
        <v-form v-model="formValid" v-on:submit.prevent>
          <v-row dense>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="formValues.label"
                :rules="[rules.required,rules.charLimit,rules.charMinLimit, ruleUniqueLabel]"
                label="Title/label for the address"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="3" v-if="false">
              <v-select
                v-model="formValues.alternate_address_flag"
                :items="alternateAddressFlagSelectDetail"
                :rules="[rules.required]"
                label="Using Alternate Address"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="formValues.usage_code"
                :items="usageCodeSelectDetail"
                :rules="[rules.required]"
                label="Usage Type"
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense v-if="false">
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="formValues.customer_name_1"
                :rules="[rules.required,rules.charLimit,rules.charMinLimit]"
                label="Customer Surname or Organisation"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="formValues.customer_name_2"
                :rules="[rules.charLimit,rules.charMinLimit]"
                label="Customer Firstname or Organisation (part 2)"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="formValues.usage_code"
                :items="usageCodeSelectDetail"
                :rules="[rules.required]"
                label="Usage Type"
                outlined
              />
            </v-col>

          </v-row>

          <v-expand-transition>
            <div v-if="formValues.alternate_address_flag=='T'">
            <h3 class="mb-3" v-if="false">Alternate Address Contact Details</h3>
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="formValues.customer_contact_name_1"
                  :rules="formValues.alternate_address_flag == 'T' ? [rules.required,rules.charLimit,rules.charMinLimit] : [rules.charLimit,rules.charMinLimit]"
                  label="Contact Surname or Organisation"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="formValues.customer_contact_name_2"
                  :rules="[rules.charLimit,rules.charMinLimit]"
                  label="Contact Firstname or Organisation (part 2)"
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="formValues.customer_contact_nr"
                  :rules="formValues.alternate_address_flag == 'T' ? [rules.required,rules.phone, rules.charLimit,rules.charMinLimit] : [rules.phone, rules.charLimit,rules.charMinLimit]"
                  label="Contact Number"
                  outlined
                />
              </v-col>
            </v-row>
            </div>
          </v-expand-transition>
        </v-form>

        <AddressDetailsForm
          v-model="address"
          :value="address"
          show-search
          hideTick
          showManualEntry
          :defaultShow="genericIpndData == null ? 'auto' : 'manual'"
          ref="addressDetailsForm"
        />

      </v-window-item>
      <v-window-item :value="2">
        <h2>Matching Addresses</h2>
        <p>Matching address found. Please select the a suitable address to continue.</p>
        <v-radio-group v-if="matchedAddresses != null" v-model="selectedAddress" class="mt-0 px-3">
          <v-radio
            v-for="(address, index) in matchedAddresses"
            :key="index"
            :value="address"
            class="mb-3">
            <template v-slot:label>
              {{ address.address_string }}
            </template>
          </v-radio>

        </v-radio-group>
      </v-window-item>
      <v-window-item :value="3">
        <h2>Confirm Address</h2>
        <p>Confirm these details as the Emergerncy Service Address for this Account.</p>
        <h4 v-if="selectedAddress != null">{{ selectedAddress.address_string }}</h4>
        <IPNDDetailsDisplayComponent :ipnd-data="displayComponentDetails" />
      </v-window-item>
      <v-window-item :value="4">
        <v-row no-gutters>
          <v-col cols="12" class="text-center py-4">
            <v-icon size="120" color="success">mdi-check-circle-outline</v-icon>
            <p class="text-h6">Emergency Service Address confirmed successfully</p>
            <p v-if="selectedAddress != null">Address: {{ selectedAddress.address_string }}</p>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>

    <template v-slot:actions>
      <v-btn
        :loading="busy"
        text
        @click="addressLibraryDialog = true"
      >Open Address Library</v-btn>
      <v-spacer />
      <v-btn
        :loading="busy"
        text
        @click="next"
        :disabled="nextStepDisabled"
      >Submit address</v-btn>
    </template>
    <UpdateAddressDialog @address-selected="libaryAddressSelected" v-model="addressLibraryDialog" :customerId="portaAccountData.i_customer" typeSwitch="customer-store" title="Customer Address Libary" yesText="Select"/>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import addressDataMixin from "../../../mixins/addressDataMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import AddressDetailsForm from "../../pieces/Forms/AddressDetailsForm.vue";
import AddressText from "../../pieces/Address/AddressText.vue";
import UpdateAddressDialog from "../Addresses/UpdateAddressDialog.vue";
import IPNDDetailsDisplayComponent from "../../pieces/IPND/IPNDDetailsDisplayComponent.vue";
const StreetNumberRegex = /^(\d{1,5})([A-Z])?(?:\-(\d{1,5})([A-Z])?)?$/;
const NameRegex = /^[A-Z](?:(?:[A-Za-z -]+)?[A-Za-z])?$/;


/**
 * Note this is used to update the NetSip IPND.
 * It would need updating to cater for other vendors and there method.
 * ATM this is just NetSip.
 */
export default {
  name: 'EditTrunkIPNDAddressDialog',
  mixins: [apiMixin, dialogMixin, trunkSettingsDataMixin,GlobalHelperMixin, addressDataMixin],
  components: { IPNDDetailsDisplayComponent, UpdateAddressDialog, AddressDetailsForm, ComplexDialog },
  data: () => ({
    step:1,
    formValues:{
      // "customer_name_1":null,
      // "customer_name_2":null,
      "label":null,
      "usage_code":null,
      "alternate_address_flag":'T',
      "customer_contact_name_1":null,
      "customer_contact_name_2":null,
      "customer_contact_nr":null,
    },
    addressLibraryDialog:false,
    usageCodeSelectDetail:[
      { value: 'B', text: 'Business service' },
      { value: 'C', text: 'Charity service' },
      { value: 'G', text: 'Government service' },
      { value: 'R', text: 'Residential service' },
      { value: 'N', text: 'usage is not available' },
    ],
    alternateAddressFlagSelectDetail:[
      { value: 'F', text: 'No' },
      { value: 'T', text: 'Yes' },
    ],
    address:null,
    addressFromLibrary:null,
    matchedAddresses:null, //address return as matches.
    selectedAddress: null, //select address record
    busy: false,
    // isValid: false,
    formValid:false,
    rules: {
      numeric: v => !isNaN(v) || 'Must be whole number',
      charLimit: v => (!v || v.length <= 40) || 'Character limit of 40',
      charMinLimit: v => (!v || v.length > 2) || 'Character minimum limit of 3',

      required: v => !!v || 'Required',
      postcode: v => (!v || /^[0-7][0-9]{3}$/.test(v)) || 'Invalid Postcode',
      street_number: [
        v => (!v || !/\s+/.test(v)) || 'Cannot contain spaces',
        v => (!v || StreetNumberRegex.test(v)) || 'Invalid street number',
      ],
      street_name: [
        v => !!v || 'Required',
        //v => (v != null && v.length <= 25) || 'Cannot be more than 25 characters',
        v => NameRegex.test(v) || 'Invalid street name',
      ],
      suburb: [
        v => !!v || 'Required',
        //v => (v != null && v.length <= 40) || 'Cannot be more than 40 characters',
        v => NameRegex.test(v) || 'Invalid suburb name',
      ],
      phone: v => (!v || (/^(?:0\d{9})?$/.test(v) ||  /^0\d{9}$/.test(v))) || 'Invalid Contact Number',
    }
  }),
  computed: {
    isBusy: function () {
      return this.busy || this.loading;
    },
    // Used to trigger the next button in the dialog.
    nextStepDisabled: function( ){
      if(this.step == 1){
        return this.address == null || !this.formValid;
      }else if(this.step == 2){
        return this.selectedAddress == null;
      }else if(this.step == 3){
        return false;
      }
      return true;
    },
    backStepDisabled: function( ){
      if(this.step == 4){
        return true;
      }

      return false;
    },
    nextLabel: function( ){
      if(this.step == 1){
        return 'Submit address';
      }else if(this.step == 2){
        return 'Select address';
      }else if(this.step == 3){
        return 'Confirm address';
      }else if(this.step == 4){
        return 'Close';
      }else{
        return 'Error';
      }
    },

    displayComponentDetails: function( ){
      let mergedObj = { ...this.selectedAddress, ...this.formValues};
      return mergedObj;
    }

  },
  methods: {
    reset() {
      this.step = null;
      this.address = null;
      this.matchedAddresses = null;
      this.selectedAddress = null;
      this.formValues = {
        // "customer_name_1":null,
        // "customer_name_2":null,
        "label":null,
        "usage_code":null,
        "alternate_address_flag":'T',
        "customer_contact_name_1":null,
        "customer_contact_name_2":null,
        "customer_contact_nr":null,
      };
      this.address = {};
      this.address.floor_type = null;
      // let json = '{ "building_type": null, "building_number": "", "floor_type": null, "floor_number": "", "street_number": "", "street_name": "", "street_type": null, "street_suffix": null, "suburb": "", "state": null, "postcode": "" } ';
      // this.address = JSON.parse(json);

      // { "building_type": null, "building_number": null, "floor_type": null, "floor_number": null, "street_number": "876", "street_name": "Tress", "street_type": "ST", "street_suffix": null, "suburb": "Mount Pleasant", "state": "VIC", "postcode": "3350" }

      if(this.genericIpndData != null) {
        this.address.building_number = this.genericIpndData.building_1st_nr;
        this.address.building_type = this.genericIpndData.building_type;
        this.address.floor_number = this.genericIpndData.building_floor_number;
        if (this.address.floor_number !== null && this.address.floor_number !== undefined && this.address.floor_number !== '') {
          this.address.floor_type = 'FL';
        }

        let value = this.genericIpndData.street_house_nr_1;
        if (value !== null && value !== undefined && value !== '') {
          value = this.genericIpndData.street_house_nr_2;
          if (value !== null && value !== undefined && value !== '') {
            this.address.street_number = this.genericIpndData.street_house_nr_1 + '' + this.genericIpndData.street_house_nr_1_suffix;
            this.address.street_number += '-' + this.genericIpndData.street_house_nr_1 + '' + this.genericIpndData.street_house_nr_1_suffix;
          } else {
            this.address.street_number = this.genericIpndData.street_house_nr_1 + '' + this.genericIpndData.street_house_nr_1_suffix;
          }
        }

        this.address.street_name = this.genericIpndData.street_name_1;
        this.address.street_suffix = this.genericIpndData.street_suffix_1;
        if (this.addressData.street_types_netsip_convert[this.genericIpndData.street_type_1]) {
          this.address.street_type = this.addressData.street_types_netsip_convert[this.genericIpndData.street_type_1];
        } else {
          this.address.street_type = this.genericIpndData.street_type_1;
        }
        this.address.suburb = this.genericIpndData.address_locality;
        this.address.postcode = this.genericIpndData.address_postcode;
        this.address.state = this.genericIpndData.address_state;

        // this.formValues.customer_name_1 = this.genericIpndData.customer_name_1;
        // this.formValues.customer_name_2 = this.genericIpndData.customer_name_2;
        //this.formValues.usage_code = this.genericIpndData.usage_code
        this.formValues.usage_code = this.stringToUsageCode(this.genericIpndData.usage_string);

        this.formValues.label = this.genericIpndData.label;
        this.formValues.alternate_address_flag = this.genericIpndData.alternate_address_flag;

        //use the contact data if the name is empty. AS it has been used wrong in the past
        // if (this.genericIpndData.alternate_address_flag == null || this.genericIpndData.customer_name_1 == '') {
        //   this.formValues.customer_name_1 = this.genericIpndData.customer_contact_name_1
        //   this.formValues.customer_name_2 = this.genericIpndData.customer_contact_name_2
        // }

        if (this.genericIpndData.alternate_address_flag == 'T') {
          this.formValues.customer_contact_name_1 = this.genericIpndData.customer_contact_name_1
          this.formValues.customer_contact_name_2 = this.genericIpndData.customer_contact_name_2
          this.formValues.customer_contact_nr = this.genericIpndData.customer_contact_nr
        }
      }
    },
    libaryAddressSelected(address){
      this.$refs.addressDetailsForm.addressSelected(address);
    },
    ruleUniqueLabel(label){
      if(this.currentIpndList == null){
        return true; //valid
      }

      let found = false;
      this.currentIpndList.forEach(function(ipnd){
        if(label == ipnd.label){
          found = true;
          return;
        }
      });
      if(found){
        return 'Title/Label must be unique. Label is already in use.';
      }
      return true;
    },
    next( ){
      if(this.step == 1 ){
        this.validateAddress();
      }else if(this.step == 2){
        this.step = 3;
      }else if(this.step == 3){
        // if(!this.fixAddress( )){
        //   this.reset( );
        // }else {
          if (this.returnValues) {
            //emit the selected values
            let mergedObj = { ...this.selectedAddress, ...this.formValues };
            this.$emit('selected', mergedObj);
            this.finish();
          } else {
            //note that the confirmAddress process runs a refresh request.
            this.confirmAddress();
          }
        // }
      }
    },
    back( ){
      if(this.step == 3 && this.matchedAddresses.length == 1){
        this.step = 1;
      }else {
        this.step--;
      }
    },
    finish( ){
      this.visible = false;
    },

    /**
     * This is used to validate the address with NetSip. It can return 1 to many results.
     * Note that this logic may need adjusting in the future if more than one vendor supplies IPND details.
     * At the time of writing the only supplier with IPNDs is NetSip. So we can run the check via NetSip.
     * If this was editing an IPND from another vendor when it would make sense to match
     * the address validation with the sane vendor as the IPND being edited.
     *
     * Also note that the values passed are the address values returned from the NetSip address validation.
     * This is what the system expects.
     * @returns {Promise<void>}
     */
    async validateAddress() {
      this.busy = true;
      this.Api.setHttpObject({timeout:60000});

      let env = this.isPortaStaging()?'s':'p';
      let details = this.getVenDetails( );

      let params = {
        "address":this.address,
        "values":this.formValues
      };

      const response = await this.Api.send('post', 'ipnds/'+details['1']['vn'][env]+'/address-search', params);
      this.Api.setHttpObject({timeout:20000});
      if (response.success) {
        // document.dispatchEvent(new CustomEvent('refreshRequested'));
        // this.visible = false;
        if(response.data.length == 1){
          this.matchedAddresses = response.data;
          this.selectedAddress = response.data[0];
          this.step = 3;
        }else {
          this.matchedAddresses = response.data;
          this.step = 2;
        }
      }
      this.busy = false;
    },

    /**
     * Send address to confirm
     * @returns {Promise<void>}
     */
    async confirmAddress() {
      /*
      The selectedAddress is returned with all data. So its inlude other none relevant information.
       */
      let addressKeys = [
        "building_type",
        "building_1st_nr",
        "building_1st_suffix",
        "building_2nd_nr",
        "building_2nd_nr_suffix",
        "building_floor_type",
        "building_floor_number",
        "street_house_nr_1",
        "street_house_nr_1_suffix",
        "street_house_nr_2",
        "street_house_nr_2_suffix",
        "street_name_1",
        "street_type_1",
        "street_suffix_1",
        "address_locality",
        "address_state",
        "address_postcode",
      ];

      let params = {
        "address": { },
        "values":this.formValues
      };

      let self = this;
      addressKeys.forEach(function (key) {
        params.address[key] = null;
        if(self.selectedAddress[key] != null){
          params.address[key] = self.selectedAddress[key];
        }
      });

      this.busy = true;
      if(this.genericIpndData == null){
        console.error("This process is obsolete. Return to caller and submit IPND with the matching process.");
        // const response = await this.Api.send('post', 'trunks/'+this.portaAccountData.i_account+'/emergency-services-addresses', params);
        // if (response.success) {
        //   document.dispatchEvent(new CustomEvent('refreshRequested'));
        //   this.step = 4;
        // }
      }else{
        const response = await this.Api.send('put','trunks/'+this.portaAccountData.i_account+'/emergency-services-addresses/'+ Object.keys(this.genericIpndData.ids)[0] + '/' + this.genericIpndData.ids[Object.keys(this.genericIpndData.ids)[0]][0]  + '/confirm', params);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.step = 4;
        }
      }
      this.busy = false;
    },

    stringToUsageCode(text){
      const item = this.usageCodeSelectDetail.find(item => item.text == text);
      return item ? item.value : null; // Return text or a default message
    },
  },
  watch: {
    visible(value) {
      this.reset();
    },
  },
  props: {
    portaAccountData: {
      type: Object,
      default: null,
    },
    genericIpndData:{
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    /*
     * this is we want to return the values rather process the results.
     * This is useful when we create the IPND before processing DIDs where we just want to values.
     */
    returnValues:{
      type: Boolean,
      default: false,
    },
    /*
      This is here for the unique label check.
      IE we run over existing names to check for a match.
      Note this list can be different to the ipnd_list from the portaAccountData.
     */
    currentIpndList:{
      type: Array,
      default:null
    }
  },
  created(){
    this.reset();
  }
};
</script>